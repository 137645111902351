<div id="topNav">
  <mat-toolbar color="primary" style="height: 50px;">
    <button mat-icon-button
            (click)="toggleMinMaxLeftMenu()"
            style="float: left; margin-top: 2px; margin-left: -9px; margin-right: 8px; color: #ccc;">
      <fa-icon [icon]="faBars" style="font-size: 0.85rem;" [fixedWidth]="true"></fa-icon>
    </button>
    <span class="navbar-brand"
          style="display: inline-block; width: 115px; margin-left: 0;"
          (click)="goHome()">
      <img src="assets/img/logo-white-40w.png"
           fetchpriority="high"
           style="width: 30px; margin-top: -4px; margin-right: -5px;" /> &nbsp;CUB </span>
    <span class="fill-space"></span>
    <span *ngIf="daysPasswordExpiresIn() <= 15">
      <button mat-button
              matTooltip="Password Expiring Soon - Click to Update"
              (click)="openPasswordChange()"
              style="margin-right: 10px; color: #f0ad4e;">
        <fa-icon [icon]="faExclamationTriangle"
                 class="fa-lg"
                 style="margin-right: 5px;"></fa-icon> {{ getPasswordMessage() }} </button>
    </span>
    <div style="float: right; margin-right: 50px;">
      <form class="form-inline mt-2 mt-md-0"
            style="margin-right: 5px; margin-left: 10px;">
        <input #searchBox
               id="searchBox"
               name="searchBox"
               class="form-control"
               type="search"
               placeholder="Enterprise Search"
               (mousedown)="openSearch()"
               (keyup)="searchTerm$.next($event.target.value); checkClose($event.target.value);">
      </form>
    </div>
    <div style="float: right;">
      <button id="btnYardi"
              mat-icon-button
              matTooltip="Yardi"
              (click)="openYardiOne()"
              style="margin-right: 6px;">
        <img src="../../../assets/img/yardi-white.png"
             style="width: 24px; vertical-align: middle; margin-top: -10px;" />
      </button>
      <button id="btnPowerBI"
              mat-icon-button
              matTooltip="Open Power BI"
              [matMenuTriggerFor]="powerBiMenu"
              style="margin-right: 6px;">
        <img src="../../../assets/img/powerbi-logo-white.png"
             style="width: 20px; height: 20px; vertical-align: middle; margin-top: -12px;" />
      </button>
      <mat-menu #powerBiMenu="matMenu"
                [yPosition]="'below'"
                [xPosition]="'before'"
                [overlapTrigger]="false">
        <button mat-menu-item
                (click)="openPowerBI()"> Go to Power BI </button>
        <hr class="menu-separator" />
        <button mat-menu-item
                *ngFor="let workspace of powerBIWorkspaces"
                [matMenuTriggerFor]="workspaceMenu"
                (mouseover)="getReportsInWorkspace(workspace.id)"> {{ workspace.name }} </button>
      </mat-menu>
      <mat-menu #workspaceMenu="matMenu"
                [yPosition]="'below'"
                [xPosition]="'after'"
                [overlapTrigger]="false">
        <button mat-menu-item
                *ngFor="let report of reportsInWorkspace"
                (click)="openPowerBIReport(report)"> {{ report.name }} </button>
      </mat-menu>
      <button id="btnOffice"
              mat-icon-button
              matTooltip="Open Microsoft 365"
              (click)="openOffice()"
              style="margin-right: 6px;">
        <img src="../../../assets/img/office-logo-white.png"
             style="width: 16px; height: 18px; vertical-align: middle; margin-top: -12px;" />
      </button>
      <button id="btnSharepoint"
              mat-icon-button
              matTooltip="Open SharePoint"
              (click)="openSharePoint()"
              style="margin-right: 6px;">
        <img src="../../../assets/img/sharepoint-logo-white-100x.png"
             style="width: 20px; height: 20px; vertical-align: middle; margin-top: -12px;" />
      </button>
      <button id="btnEgnyte"
              mat-icon-button
              matTooltip="Open Egnyte"
              (click)="openEgnyte()"
              style="margin-right: 6px;">
        <img src="../../../assets/img/egnyte-logo-white.png"
             style="width: 20px; height: 20px; vertical-align: middle; margin-top: -12px;" />
      </button>
    </div>
    <div style="float: right; border-right: solid 1px #425582; margin: 20px 10px 20px 0;">
      &nbsp;
    </div>
    <button id="badData"
            mat-icon-button
            matBadge="{{ badDataCount }}"
            matBadgeHidden="{{ badDataCount === 0 }}"
            matBadgePosition="after"
            matBadgeSize="small"
            matBadgeColor="warn"
            [matMenuTriggerFor]="badDataMenu"
            [disabled]="badDataDisabled"
            matTooltip="Help / Bad Data"
            style="margin-right: 5px; font-size: 0.8rem;">
      <fa-icon [icon]="faQuestionSquare" style="float: right; font-size: 1.0rem;" [fixedWidth]="true"></fa-icon>
    </button>
    <mat-menu #badDataMenu="matMenu"
              [yPosition]="'below'"
              [xPosition]="'after'"
              [overlapTrigger]="false">
      <span class="group-label">Help</span>
      <button mat-menu-item
              (click)="openHelp()">
              <fa-icon [icon]="faQuestionSquare" style="font-size: 1.0rem; margin-right: 5px;" [fixedWidth]="true"></fa-icon>
              General Help
      </button>
      <button mat-menu-item
              (click)="openMISHelp()">
              <fa-icon [icon]="faLaptopMedical" style="font-size: 1.0rem; margin-right: 5px;" [fixedWidth]="true"></fa-icon>
              MIS Help
      </button>
      <button mat-menu-item
              (click)="openSendToEmailWindow()">
              <fa-icon [icon]="faEnvelope" style="font-size: 1.0rem; margin-right: 5px;" [fixedWidth]="true"></fa-icon>
              Email MIS
      </button>
      <hr class="menu-separator" />
      <span class="group-label">Bad Data</span>
      <button mat-menu-item
              (click)="openBadDataTicket()">
              <fa-icon [icon]="faBullhorn" style="font-size: 1.0rem; margin-right: 5px;" [fixedWidth]="true"></fa-icon>
              Report Bad Data </button>
      <button mat-menu-item
              (click)="openBadDataListing()">
              <fa-icon [icon]="faListCheck" style="font-size: 1.0rem; margin-right: 5px;" [fixedWidth]="true"></fa-icon>
              View Listing </button>
    </mat-menu>
    <button mat-icon-button
            [matMenuTriggerFor]="recentMenu"
            style="margin-right: 0px;"
            (click)="logClick('Recents');">
      <fa-icon [icon]="faHistory" style="font-size: 1.0rem;" [fixedWidth]="true"></fa-icon>
    </button>
    <mat-menu #recentMenu="matMenu"
              [yPosition]="'below'"
              [xPosition]="'before'"
              [overlapTrigger]="false">
      <div *ngFor="let item of recents">
        <span class="group-label"
              *ngIf="item.subMenu.length > 0">{{ item.name }}</span>
        <div *ngIf="item.subMenu.length > 0" style="">
          <cub-new-menu-item *ngFor="let item of item.subMenu"
                              [menuItem]="item"></cub-new-menu-item>
        </div>
      </div>
    </mat-menu>
    <cub-new-menu style="margin-right: 0; margin-top: 0;"></cub-new-menu>
    <button mat-icon-button
            [matMenuTriggerFor]="userMenu"
              style="">
      <img *ngIf="user"
            [src]="getPhotoUrl()"
            style="width: 30px; height: 30px; border-radius: 28px; margin-top: -3px;" />
    </button>
    <mat-menu #userMenu="matMenu"
              style="margin-top: 10px;"
              [yPosition]="'below'"
              [xPosition]="'before'"
              [overlapTrigger]="false">
      <cub-new-menu-item *ngFor="let item of userMenuList"
                          [menuItem]="item"></cub-new-menu-item>
      <cub-new-menu-item [menuItem]="logoutMenuItemSep"></cub-new-menu-item>
      <cub-new-menu-item [menuItem]="logoutMenuItem"
                          (menuClicked)="userMenuItemClicked($event)"></cub-new-menu-item>
    </mat-menu>
  </mat-toolbar>
</div>
