import { Component, OnInit } from '@angular/core';
import { UserMenu } from '../../user/user-menu';
import { UserService } from '../user.service';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { NewMenuItemComponent } from '../new-menu-item/new-menu-item.component';
import { NgFor } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { LoggerService } from '../logger.service';


@Component({
    selector: 'cub-new-menu',
    templateUrl: './new-menu.component.html',
    styleUrls: ['./new-menu.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, FontAwesomeModule, NgFor, NewMenuItemComponent]
})
export class NewMenuComponent implements OnInit {

  menuList: UserMenu[] = [];
  faPlus = faPlus;

  constructor(
    private loggerService: LoggerService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getMenu();
  }

  getMenu(): void {
    this.userService.userMenu$.subscribe((menu) => {
      if (menu != null) {
        this.menuList = menu.subMenu[2].subMenu;
      }
    });
  }

  logClick(sys: string): void {
    this.loggerService.logPageVisit('top menu clicked', sys);
  }
}
